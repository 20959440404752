import React from 'react';

import Layout from '../layouts/index';
//import {isContentOnly} from '../utils/location';
import './privacy.scss';

const PrivacyPage = (props) => {
  return (
    <Layout location={props.location}>
      <div className="header-container">
        <div className="header-container-text">
          <h1>Privacy Policy</h1>
        </div>
      </div>
      <div className="privacy-container">
        <div className="privacy-text-container">
          <h4>Last updated September 25, 2018</h4>

          <p>
            Thank you for choosing to be part of our community at Three
                    Spatial LLC “<strong>Company</strong>”, “<strong>we</strong>
            ”, “<strong>us</strong>”, or "<strong>our</strong>"). We are
            committed to protecting your personal information and your
            right to privacy. If you have any questions or concerns
            about our policy, or our practices with regards to your
                    personal information, please contact us at{' '}
            <a
              href="mailto:nick@threespatial.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              nick@threespatial.com
                    </a>
            .
                </p>

          <p>
            When you visit our website www.wrangle.us, mobile
            application, and use our services, you trust us with your
            personal information. We take your privacy very seriously.
            In this privacy notice, we describe our privacy policy. We
            seek to explain to you in the clearest way possible what
            information we collect, how we use it and what rights you
            have in relation to it. We hope you take some time to read
            through it carefully, as it is important. If there are any
            terms in this privacy policy that you do not agree with,
            please discontinue use of our Sites or Apps and our
            services.
                </p>

          <p>
            This privacy policy applies to all information collected
            through our website (such as www.wrangle.us), mobile
                    application, ("<strong>Apps</strong>"), and/or any related
        services, sales, marketing or events (we refer to them
        collectively in this privacy policy as the "
                    <strong>Sites</strong>").
                </p>

          <p>
            <strong>
              Please read this privacy policy carefully as it will
              help you make informed decisions about sharing your
              personal information with us.
                    </strong>
          </p>

          <h5>TABLE OF CONTENTS</h5>

          <p>
            <a href="#1">1. WHAT INFORMATION DO WE COLLECT?</a>
          </p>
          <p>
            <a href="#2">2. HOW DO WE USE YOUR INFORMATION?</a>
          </p>
          <p>
            <a href="#3">
              3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                    </a>
          </p>
          <p>
            <a href="#4">
              4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </a>
          </p>
          <p>
            <a href="#5">5. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
          </p>
          <p>
            <a href="#6">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
          </p>
          <p>
            <a href="#7">7. DO WE COLLECT INFORMATION FROM MINORS?</a>
          </p>
          <p>
            <a href="#8">8. WHAT ARE YOUR PRIVACY RIGHTS?</a>
          </p>
          <p>
            <a href="#9">
              9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </a>
          </p>
          <p>
            <a href="#10">10. DO WE MAKE UPDATES TO THIS POLICY?</a>
          </p>
          <p>
            <a href="#11">
              11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                    </a>
          </p>

          <h3>
            <a href="#1" name="1">
              {' '}
            </a>
            1. WHAT INFORMATION DO WE COLLECT?
                </h3>

          <h5>Personal information you disclose to us</h5>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              We collect personal information that you provide to us
              such as name, address, contact information, passwords
              and security data, and payment information.
                    </em>
          </p>

          <p>
            We collect personal information that you voluntarily provide
            to us when registering at the Sites or Apps, expressing an
            interest in obtaining information about us or our products
            and services, when participating in activities on the Sites
            or Apps (such as posting messages in our online forums or
            entering competitions, contests or giveaways) or otherwise
            contacting us.
                </p>

          <p>
            The personal information that we collect depends on the
            context of your interactions with us and the Sites or Apps,
            the choices you make and the products and features you use.
            The personal information we collect can include the
            following:
                </p>

          <p>
            <strong>Name and Contact Data.</strong> We collect your
            first and last name, email address, postal address, phone
            number, and other similar contact data.
                </p>

          <p>
            <strong>Credentials.</strong> We collect passwords, password
            hints, and similar security information used for
            authentication and account access.
                </p>

          <p>
            <strong>Payment Data.</strong> We collect data necessary to
            process your payment if you make purchases, such as your
            payment instrument number (such as a credit card number),
            and the security code associated with your payment
            instrument. All payment data is stored by our payment
            processor and you should review its privacy policies and
            contact the payment processor directly to respond to your
            questions.
                </p>

          <p>
            All personal information that you provide to us must be
            true, complete and accurate, and you must notify us of any
            changes to such personal information.
                </p>

          <h5>Information automatically collected</h5>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              Some information – such as IP address and/or browser and
              device characteristics – is collected automatically when
              you visit our Sites or Apps.
                    </em>
          </p>

          <p>
            We automatically collect certain information when you visit,
            use or navigate the Sites or Apps. This information does not
            reveal your specific identity (like your name or contact
            information) but may include device and usage information,
            such as your IP address, browser and device characteristics,
            operating system, language preferences, referring URLs,
            device name, country, location, information about how and
            when you use our Sites or Apps and other technical
            information. This information is primarily needed to
            maintain the security and operation of our Sites or Apps,
            and for our internal analytics and reporting purposes.
                </p>

          <p>
            Like many businesses, we also collect information through
            cookies and similar technologies.
                </p>

          <h5>Information collected through our Apps</h5>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              We may collect information regarding your mobile device,
              push notifications, when you use our apps.
                    </em>
          </p>

          <p>
            If you use our Apps, we may also collect the following
            information:
                </p>

          <ul>
            <li>
              <p>
                <strong>Mobile Device Access</strong>. We may
                request access or permission to certain features
                from your mobile device, including your mobile
                device’s camera, microphone, sensors, storage, and
                other features. If you wish to change our access or
                permissions, you may do so in your device’s
                settings.
                        </p>
            </li>

            <li>
              <p>
                <strong>Mobile Device Data</strong>. We may
                automatically collect device information (such as
                your mobile device ID, model and manufacturer),
                operating system, version information and IP
                address.
                        </p>
            </li>

            <li>
              <p>
                <strong>Push Notifications</strong>. We may request
                to send you push notifications regarding your
                account or the mobile application. If you wish to
                opt­out from receiving these types of
                communications, you may turn them off in your
                device’s settings.
                        </p>
            </li>
          </ul>

          <h5>Information collected from other sources</h5>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              We may collect limited data from public databases,
              marketing partners, and other outside sources.
                    </em>
          </p>

          <p>
            We may obtain information about you from other sources, such
            as public databases, joint marketing partners, as well as
            from other third parties. Examples of the information we
            receive from other sources include: social media profile
            information; marketing leads and search results and links,
            including paid listings (such as sponsored links).
                </p>

          <h3>
            <a href="#2" name="2">
              {' '}
            </a>
            2. HOW DO WE USE YOUR INFORMATION?
                </h3>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              We process your information for purposes based on
              legitimate business interests, the fulfillment of our
              contract with you, compliance with our legal
              obligations, and/or your consent.
                    </em>
          </p>

          <p>
            We use personal information collected via our Sites or Apps
            for a variety of business purposes described below. We
            process your personal information for these purposes in
            reliance on our legitimate business interests ("Business
            Purposes"), in order to enter into or perform a contract
            with you ("Contractual"), with your consent ("Consent"),
            and/or for compliance with our legal obligations ("Legal
            Reasons"). We indicate the specific processing grounds we
            rely on next to each purpose listed below.
                </p>

          <p>We use the information we collect or receive:</p>

          <ul>
            <li>
              <p>
                <strong>
                  To facilitate account creation and logon process
                  with your Consent.
                            </strong>{' '}
                If you choose to link your account with us to a
                third party account *(such as your Google or
                Facebook account), we use the information you
                allowed us to collect from those third parties to
                facilitate account creation and logon process.
                        </p>
            </li>

            <li>
              <p>
                <strong>
                  To send you marketing and promotional
                  communications with your Consent.
                            </strong>{' '}
                We and/or our third party marketing partners may use
                the personal information you send to us for our
                marketing purposes, if this is in accordance with
                your marketing preferences. You can opt­out of our
                marketing emails at any time (see the "WHAT ARE YOUR
                PRIVACY RIGHTS" below).
                        </p>
            </li>

            <li>
              <p>
                <strong>
                  To send administrative information to you
                            </strong>{' '}
                for Business Purposes, Legal Reasons and/or possibly
                for Contractual. We may use your personal
                information to send you product, service and new
                feature information and/or information about changes
                to our terms, conditions, and policies.
                        </p>
            </li>

            <li>
              <p>
                <strong>Fulfill and manage your orders</strong> for
                Contractual reasons. We may use your information to
                fulfill and manage your orders, payments, returns,
                and exchanges made through the Sites or Apps.
                        </p>
            </li>

            <li>
              <p>
                <strong>To post testimonials</strong> with your
                Consent. We post testimonials on our Sites or Apps
                that may contain personal information. Prior to
                posting a testimonial, we will obtain your consent
                to use your name and testimonial. If you wish to
                update, or delete your testimonial, please contact
                            us at{' '}
                <a
                  href="mailto:nick@threespatial.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  nick@threespatial.com
                            </a>{' '}
                and be sure to include your name, testimonial
                location, and contact information.
                        </p>
            </li>

            <li>
              <p>
                <strong>Deliver targeted advertising to you</strong>{' '}
                for our Business Purposes and/or with your Consent.
                We may use your information to develop and display
                content and advertising (and work with third parties
                who do so) tailored to your interests and/or
                location and to measure its effectiveness.
                        </p>
            </li>

            <li>
              <p>
                <strong>Request Feedback</strong> for our Business
                Purposes. We may use your information to request
                feedback and to contact you about your use of our
                Sites or Apps.
                        </p>
            </li>

            <li>
              <p>
                <strong>To protect our Sites</strong> for Business
                Purposes and/or for Legal Reasons. We may use your
                information as part of our efforts to keep our Sites
                or Apps safe and secure (for example, for fraud
                monitoring and prevention).
                        </p>
            </li>

            <li>
              <p>
                <strong>
                  To enable user­-to-user communications
                            </strong>{' '}
                with your Consent. We may use your information in
                order to enable user­to­user communications with
                each user's consent.
                        </p>
            </li>

            <li>
              <p>
                <strong>
                  To enforce our terms, conditions and policies
                            </strong>{' '}
                for Business Purposes, Legal Reasons and/or possibly
                Contractual.
                        </p>
            </li>

            <li>
              <p>
                <strong>
                  To respond to legal requests and prevent harm
                            </strong>{' '}
                for Legal Reasons. If we receive a subpoena or other
                legal request, we may need to inspect the data we
                hold to determine how to respond.
                        </p>
            </li>

            <li>
              <p>
                <strong>For other Business Purposes</strong>. We may
                use your information for other Business Purposes,
                such as data analysis, identifying usage trends,
                determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Sites or
                Apps, products, services, marketing and your
                experience.
                        </p>
            </li>
          </ul>

          <h3>
            <a href="#3" name="3">
              {' '}
            </a>
            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </h3>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              We only share information with your consent, to comply
              with laws, to protect your rights, or to fulfill
              business obligations.
                    </em>
          </p>

          <p>
            We only share and disclose your information in the following
            situations:
                </p>

          <ul>
            <li>
              <p>
                <strong>Compliance with Laws</strong>. We may
                disclose your information where we are legally
                required to do so in order to comply with applicable
                law, governmental requests, a judicial proceeding,
                court order, or legal process, such as in response
                to a court order or a subpoena (including in
                response to public authorities to meet national
                security or law enforcement requirements).
                        </p>
            </li>

            <li>
              <p>
                <strong>Vital Interests and Legal Rights</strong>.
                We may disclose your information where we believe it
                is necessary to investigate, prevent, or take action
                regarding potential violations of our policies,
                suspected fraud, situations involving potential
                threats to the safety of any person and illegal
                activities, or as evidence in litigation in which we
                are involved.
                        </p>
            </li>

            <li>
              <p>
                <strong>
                  Vendors, Consultants and Other Third­-Party
                  Service Providers
                            </strong>
                . We may share your data with third party vendors,
                service providers, contractors or agents who perform
                services for us or on our behalf and require access
                to such information to do that work. Examples
                include: payment processing, data analysis, email
                delivery, hosting services, customer service and
                marketing efforts. We may allow selected third
                parties to use tracking technology on the Sites or
                Apps, which will enable them to collect data about
                how you interact with the Sites or Apps over time.
                This information may be used to, among other things,
                analyze and track data, determine the popularity of
                certain content and better understand online
                activity. Unless described in this Policy, we do not
                share, sell, rent or trade any of your information
                with third parties for their promotional purposes.
                        </p>
            </li>

            <li>
              <p>
                <strong>Business Transfers</strong>. We may share or
                transfer your information in connection with, or
                during negotiations of, any merger, sale of company
                assets, financing, or acquisition of all or a
                portion of our business to another company.
                        </p>
            </li>

            <li>
              <p>
                <strong>With your Consent</strong>. We may disclose
                your personal information for any other purpose with
                your consent.
                        </p>
            </li>

            <li>
              <p>
                <strong>Other Users</strong>. When you share
                personal information or otherwise interact with
                public areas of the Sites or Apps, such personal
                information may be viewed by all users and may be
                publicly distributed outside the Sites or Apps in
                perpetuity. Similarly, other users will be able to
                view descriptions of your activity, communicate with
                you within our Sites or Apps, and view your profile.
                        </p>
            </li>
          </ul>

          <h3>
            <a href="#4" name="4">
              {' '}
            </a>
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </h3>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              We may use cookies and other tracking technologies to
              collect and store your information.
                    </em>
          </p>

          <p>
            We may use cookies and similar tracking technologies (like
            web beacons and pixels) to access or store information.
            Specific information about how we use such technologies and
            how you can refuse certain cookies is set out in our Cookie
            Policy.
                </p>

          <h3>
            <a href="#5" name="5">
              {' '}
            </a>
            5. HOW LONG DO WE KEEP YOUR INFORMATION?
                </h3>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              We keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy policy
              unless otherwise required by law.
                    </em>
          </p>

          <p>
            We will only keep your personal information for as long as
            it is necessary for the purposes set out in this privacy
            policy, unless a longer retention period is required or
            permitted by law (such as tax, accounting or other legal
            requirements). No purpose in this policy will require us
            keeping your personal information for longer than the period
            of time in which users have an account with us.
                </p>

          <p>
            When we have no ongoing legitimate business need to process
            your personal information, we will either delete or
            anonymize it, or, if this is not possible (for example,
            because your personal information has been stored in backup
            archives), then we will securely store your personal
            information and isolate it from any further processing until
            deletion is possible.
                </p>

          <h3>
            <a href="#6" name="6">
              {' '}
            </a>
            6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </h3>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              We aim to protect your personal information through a
              system of organizational and technical security
              measures.
                    </em>
          </p>

          <p>
            We have implemented appropriate technical and organizational
            security measures designed to protect the security of any
            personal information we process. However, please also
            remember that we cannot guarantee that the internet itself
            is 100% secure. Although we will do our best to protect your
            personal information, transmission of personal information
            to and from our Sites or Apps is at your own risk. You
            should only access the services within a secure environment.
                </p>

          <h3>
            <a href="#7" name="7">
              {' '}
            </a>
            7. DO WE COLLECT INFORMATION FROM MINORS?
                </h3>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              We do not knowingly collect data from or market to
              children under 18 years of age.
                    </em>
          </p>

          <p>
            We do not knowingly solicit data from or market to children
            under 18 years of age. By using the Sites or Apps, you
            represent that you are at least 18 or that you are the
            parent or guardian of such a minor and consent to such minor
            dependent’s use of the Sites or Apps. If we learn that
            personal information from users less than 18 years of age
            has been collected, we will deactivate the account and take
            reasonable measures to promptly delete such data from our
            records. If you become aware of any data we have collected
                    from children under age 18, please contact us at{' '}
            <a
              href="mailto:nick@threespatial.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              nick@threespatial.com
                    </a>
            .
                </p>

          <h3>
            <a href="#8" name="8">
              {' '}
            </a>
            8. WHAT ARE YOUR PRIVACY RIGHTS?
                </h3>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              In some regions, such as the European Economic Area, you
              have rights that allow you greater access to and control
              over your personal information. You may review, change,
              or terminate your account at any time.
                    </em>
          </p>

          <p>
            In some regions (like the European Economic Area), you have
            certain rights under applicable data protection laws. These
            may include the right (i) to request access and obtain a
            copy of your personal information, (ii) to request
            rectification or erasure; (iii) to restrict the processing
            of your personal information; and (iv) if applicable, to
            data portability. In certain circumstances, you may also
            have the right to object to the processing of your personal
            information. To make such a request, please use the contact
            details provided below. We will consider and act upon any
            request in accordance with applicable data protection laws.
                </p>

          <p>
            If we are relying on your consent to process your personal
            information, you have the right to withdraw your consent at
            any time. Please note however that this will not affect the
            lawfulness of the processing before its withdrawal.
                </p>

          <p>
            If you are resident in the European Economic Area and you
            believe we are unlawfully processing your personal
            information, you also have the right to complain to your
            local data protection supervisory authority. You can find
                    their contact details here:{' '}
            <a href="http://ec.europa.eu/justice/data­ protection/bodies/authorities/index_en.htm">
              http://ec.europa.eu/justice/data­
              protection/bodies/authorities/index_en.htm
                    </a>
          </p>

          <h5>Account Information</h5>

          <p>
            If you would at any time like to review or change the
            information in your account or terminate your account, you
            can:
                </p>

          <ul>
            <li>
              <p>
                Log into your account settings and update your user
                account.
                        </p>
            </li>
          </ul>

          <p>
            Upon your request to terminate your account, we will
            deactivate or delete your account and information from our
            active databases. However, some information may be retained
            in our files to prevent fraud, troubleshoot problems, assist
            with any investigations, enforce our Terms of Use and/or
            comply with legal requirements.
                </p>

          <p>
            <strong>Cookies and similar technologies</strong>: Most Web
            browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove
            cookies and to reject cookies. If you choose to remove
            cookies or reject cookies, this could affect certain
            features or services of our Sites or Apps. To opt­ out of
            interest­based advertising by advertisers on our Sites or
                    Apps visit{' '}
            <a href="http://www.aboutads.info/choices/">
              http://www.aboutads.info/choices/
                    </a>
            .
                </p>

          <p>
            <strong>Opting out of email marketing</strong>: You can
            unsubscribe from our marketing email list at any time by
            clicking on the unsubscribe link in the emails that we send
            or by contacting us using the details provided below. You
            will then be removed from the marketing email list –
            however, we will still need to send you service­related
            emails that are necessary for the administration and use of
            your account. To otherwise opt­out, you may:
                </p>

          <ul>
            <li>
              <p>
                Access your account settings and update preferences.
                        </p>
            </li>
          </ul>

          <h3>
            <a href="#9" name="9">
              {' '}
            </a>
            9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </h3>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              Yes, if you are a resident of California, you are
              granted specific rights regarding access to your
              personal information.
                    </em>
          </p>

          <p>
            California Civil Code Section 1798.83, also known as the
            “Shine The Light” law, permits our users who are California
            residents to request and obtain from us, once a year and
            free of charge, information about categories of personal
            information (if any) we disclosed to third parties for
            direct marketing purposes and the names and addresses of all
            third parties with which we shared personal information in
            the immediately preceding calendar year. If you are a
            California resident and would like to make such a request,
            please submit your request in writing to us using the
            contact information provided below.
                </p>

          <p>
            If you are under 18 years of age, reside in California, and
            have a registered account with the Sites or Apps, you have
            the right to request removal of unwanted data that you
            publicly post on the Sites or Apps. To request removal of
            such data, please contact us using the contact information
            provided below, and include the email address associated
            with your account and a statement that you reside in
            California. We will make sure the data is not publicly
            displayed on the Sites or Apps, but please be aware that the
            data may not be completely or comprehensively removed from
            our systems.
                </p>

          <h3>
            <a href="#10" name="10">
              {' '}
            </a>
            10. DO WE MAKE UPDATES TO THIS POLICY?
                </h3>

          <p>
            <strong>In Short</strong>:{' '}
            <em>
              Yes, we will update this policy as necessary to stay
              compliant with relevant laws.
                    </em>
          </p>

          <p>
            We may update this privacy policy from time to time. The
            updated version will be indicated by an updated “Revised”
            date and the updated version will be effective as soon as it
            is accessible. If we make material changes to this privacy
            policy, we may notify you either by prominently posting a
            notice of such changes or by directly sending you a
            notification. We encourage you to review this privacy policy
            frequently to be informed of how we are protecting your
            information.
                </p>

          <h3>
            <a href="#11" name="11">
              {' '}
            </a>
            11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                </h3>

          <p>
            If you have questions or comments about this policy, you may
            contact our Data Protection Officer (DPO), Nicholas Barbara,
                    by email at{' '}
            <a
              href="mailto:nick@threespatial.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              nick@threespatial.com
                    </a>
            , or by post to:
                </p>

          <p>
            Three Spatial LLC <br />
            Nicholas Barbara <br />
            6218 Blarwood Dr Austin, TX 78745 <br />
            United States
                </p>

          <p>
            If you have any further questions or comments about us or
                    our policies, email us at{' '}
            <a
              href="mailto:nick@threespatial.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              nick@threespatial.com
                    </a>{' '}
            or by post to:
                </p>

          <p>
            Three Spatial LLC <br />
            6218 Blarwood Dr <br />
            Austin, TX 78745 <br />
            United States <br />
            Phone: 845-264-4403
                </p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPage;
